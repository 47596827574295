import { HashLink as Link } from 'react-router-hash-link'
import { useDispatch, useSelector } from "react-redux";
import { connectM } from "./redux/blockchain/blockchainActions";
import { fetchDataM } from "./redux/data/dataActions";

import "./styles/navbar.css"
const Navbar = () => {
    const dispatch = useDispatch();
    const blockchain = useSelector((state) => state.blockchain);

    const getData = () => {
        if (blockchain.account !== "" && blockchain.smartContract !== null) {
        dispatch(fetchDataM(blockchain.account));
        }
    };

    getData();

    
    
    return (
        <nav className=" top-nav">
            
            <Link className="logo" to="/"><img src="https://i.imgur.com/q6W0QP1.png" width="63" height="60"/></Link>
                     <input id="menu-toggle" type="checkbox" />
                    <label className='menu-button-container' htmlFor="menu-toggle">
                    <div className='menu-button'></div>
                    </label>
                    <ul className="menu">
                    <li><Link to="/mint">MINT</Link></li>
                    <li><Link to="/DropsStaking">Drops Staking</Link></li>
                    <li><Link to="/DuckStaking">DuckHunt Staking</Link></li>
                    <li><Link to="/PhireStaking">PhireDuck Staking</Link></li>
                    <li><Link to="/DiamondStaking">Diamond Staking</Link></li>
                    </ul>
            
        </nav>
    );
}
 
export default Navbar;
